(function () {
	'use strict';
	angular
		.module('app')
		.controller('BreakoutCtrl', [
			'$scope',
			'$timeout',
			'seedcodeCalendar',
			'manageBreakout',
			'manageView',
			'utilities',
			'environment',
			BreakoutCtrl,
		]);

	function BreakoutCtrl(
		$scope,
		$timeout,
		seedcodeCalendar,
		manageBreakout,
		manageView,
		utilities,
		environment
	) {
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		$scope.calendar.view = seedcodeCalendar.get('view');

		$scope.availableBreakoutFields = manageBreakout.availableFields();

		$scope.$on('eventsRendered', function (event, data) {
			//Update available fields for breakout
			$scope.availableBreakoutFields = manageBreakout.availableFields();
		});

		$scope.changeView = manageView.changeView;
		$scope.changeHorizonView = changeHorizonView;

		$scope.close = close;

		$scope.help = help;

		function changeHorizonView(breakout) {
			var lastSelectedField;
			var availableFields = manageBreakout.availableFields();

			$scope.loadingView = true;

			// Set the breakout field so the view updates immediately
			$scope.calendar.config.horizonBreakoutField = breakout.name;

			$timeout(function () {
				for (var i = 0; i < availableFields.length; i++) {
					if (breakout.label === availableFields[i].label) {
						lastSelectedField = availableFields[i];
					}
				}

				manageView.changeHorizonView(breakout.name, lastSelectedField);

				$scope.loadingView = false;

				close();
			}, 50);
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}

		function help(page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		}
	}
})();
